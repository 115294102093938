<template>
  <div class="slides">
    <div ref="slides" class="swiper-container">
      <div class="swiper-wrapper">
        <div
          v-for="(promotion, key) in promotions"
          :key="key"
          class="swiper-slide"
          :style="{ backgroundImage: `url(${promotion.image_400})` }"
        ></div>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';

export default {
  name: 'Promotions',
  props: {
    promotions: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    const swiper = new Swiper(this.$refs.slides, {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  },
};
</script>
