<template>
  <div class="page--checkin-station home" :class="{ login: isLogin }">
    <div class="container-checkin-station">
      <img src="/static/img/checkin-station/logo-sociolla.png" class="logo" />
      <h2 class="heading">Kota Kasablanka Store</h2>

      <img src="/static/img/checkin-station/qr-code-img.png" class="qr-code-img" />

      <div v-if="isLogin" class="user-info">
        <h2 class="user-info__greeting">
          <span class="dark">Hello,</span><br />
          <span class="text-uppercase">{{ userProfile.first_name }} {{ userProfile.last_name }}</span>
        </h2>

        <GImageUser
          class="block-ava"
          :username="userProfile.user_name || 'jos'"
          :src="userProfile.image || false"
          class-name="user-info__ava"
        />

        <p class="user-info__copywriting">
          Cek aplikasi mobile SOCO anda untuk melihat rekomendasi produk sesuai dengan Beauty Profile kamu!
        </p>
      </div>
      <div v-else class="qrcode">
        <h4 class="qrcode-heading">SCAN QR CODE</h4>
        <p class="qrcode-copywriting">
          Lakukan scan QR Code dari mobile web atau mobile app SOCO. Arahkan scanner ke QR Code di bawah ini.
        </p>
        <div class="qrcode-wrapper">
          <qrcode-vue :value="QRcode" :size="'353'" level="H"></qrcode-vue>
        </div>
      </div>
    </div>

    <Promotions v-if="$SO.size(promotions) && !isLogin" :promotions="promotions" />
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import Promotions from './snippet/promotions';
// import Worker from "worker-loader!@/shared/worker"

// const worker = new Worker()
const Config = require('~/config/default.env').default;
// const QR_CODE_LIFE_TIME = 10 * 60 * 1000;
// const GET_TOKEN_INTERVAL = 1000;
import SocketIO from 'socket.io-client';

const socket = SocketIO(`${Config.MS_SOCKET_API_URL}/store`, { transports: ['websocket'] });

export default {
  name: 'checkinStationHome',
  components: {
    QrcodeVue,
    Promotions,
  },
  data() {
    return {
      promotions: '',
      isPromotionLoading: false,
      isConnected: false,
      socketMessage: '',
    };
  },

  computed: {
    userProfile() {
      return this.$store.state.auth.userProfile;
    },
    QRcode() {
      return this.$store.state.QRcode.value || '';
    },
    isLogin() {
      return this.$SO.size(this.$store.state.auth.userProfile);
    },
  },
  created() {
    const self = this;
    this.getPromotion();
    socket.on('qr_code_login', function (data) {
      console.log('ok');
      // if (!self.$store.state.QRcode.isLoggedIn && self.QRcode == data.qr_code) {
      self.$store.commit('authToken', data.token);
      self.$store.dispatch('getToken');
      // }
    });
  },
  methods: {
    getPromotion() {
      this.isPromotionLoading = true;
      fetch(`${Config.MS_SOCIOLLA_API_URL}/offlinestore/home/promotion`)
        .then((response) => response.json())
        .then((data) => {
          if (data.meta.code === 200) {
            this.promotions = data.data;
          } else {
            this.promotions = [];
          }
          this.isPromotionLoading = false;
        })
        .catch((error) => {
          this.promotions = [];
          this.isPromotionLoading = false;
          console.error(error);
        });
    },
  },
};
</script>
